
.color-blanc {
    color: #ffffff;
}

.bg-blau {
    background-color: #6b778d;
}
.color-blau {
    color: #6b778d;
}

h1 {
    padding: 10px;
}
h2 {
    padding: 7px;
}
h3 {
    padding: 5px;
}
h1, h2, h3 {
    color: #ffffff;
    background-color: #6b778d;
    font-weight: bold;
    padding-left: 10px;
}
strong {
    color: #6b778d;
}

.indent {
    margin-left: 10px;
    margin-right: 10px;
}

.prova-club {
    font-size: 1.2rem;
    font-weight: bold;
    color: #6b778d;
    line-height: 1.2;
}

.prova-club img {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #8a9cbd !important;
    color: #ffffff;
}
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    color: #ffffff;
}
.btn-selected, .btn-selected:hover, .btn-selected:active, .btn-selected:visited {
    background-color: #6b778d !important;
    color: #ffffff;
}

.bg-blau2 {
   background-color: #373F80;
}

a,
a:active,
a:hover,
a:focus,
a:visited {
    color: #105e62;
}

.photo-box {
    background-color: #eeeeee;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 55px;
    height: 55px;
}

.photo-box-position {
    width: 60px;
    height: 55px;
    background-color: #6b778d;
    font-size: 30px;
    font-weight: bolder;
    color: #ffffff;
    display:flex;
    justify-content:center;
    align-items:center;
}

.team {
    line-height: 1.2;
    margin:auto;
}
.team-dog {
    text-transform: uppercase;
    font-weight: bold;
}
.team-club {
    font-size: .8rem;
}
.participant-row {
    display: block;
    overflow-x: auto;
    width: 96%;
    margin: auto;
}
.participant-row[data-my="1"] {
    background-color: #6b778d;
    color: #ffffff;
}
.participant-classification {
    white-space: nowrap;
    width: 100%;
    table-layout: fixed;
}
.participant-classification col {
    width: 55px;
}
.participant-classification th,
.participant-classification td {
    border: 1px solid #6b778d;
}

.participant-row[data-my="1"] .participant-classification th,
.participant-row[data-my="1"] .participant-classification td {
    border: 1px solid #000000;
}

@media (min-width: 768px) {
    .indent {
        margin-left: 20px;
    }
    .participant-row {
        width: 90%;
    }
    h1, h2, h3 {
        padding-left: 20px;
    }
}
.separator-left {
    border-left: 2px solid #dee2e6;
}
.separator-right {
    border-right: 2px solid #dee2e6;
}
.license {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-bottom: 30px;
    position: relative;
}

.license::after {
    content: "";
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}
.license::after {
    content: "";
    background-color: #000000;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}


.license_wrap {
    padding: 10px;
}
.license_logo {
    height: 55px;
    width: auto;
    display: block;
}
.license_title,
.license_label,
.license_type,
.license_valid,
.license_fields span {
    background-color: #ffffff;
    color: #000000;
    padding-left: 5px;
    padding-right: 5px;
}
.license_title {
    text-transform: uppercase;
    font-size: 14px;
    color: #6b778d;
}
.license_label,
.license_type {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
}
.license_label,
.license_valid {
    color: #6b778d;
}
.license_fields strong {
    text-transform: uppercase;
}
.license_photo {
    max-width: 120px;
    height: auto;
}

#game-current {
    background-color: #eeeeee;
    padding-bottom: 20px;
}